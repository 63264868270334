import { createApp } from 'vue'
import App from './App.vue'
import router from "./routes"
import helpers from "./helpers"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import GoogleTranslateSelect from '@google-translate-select/vue3';

const app = createApp(App).use(router).use(ElementPlus).use(GoogleTranslateSelect);
app.config.globalProperties.$helpers = helpers;
app.mount("#app");