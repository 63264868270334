import moment from "moment";
// const API_ENDPOINT = "http://127.0.0.1:8000/api/";
const API_ENDPOINT = "https://api.baystarglobaltours.com/api/";
const BASE_URL = "https://api.baystarglobaltours.com/";
const APP_URL = "https://app.baystarglobaltours.com/";
const version = "Version 1.0.1";
const copyright = new Date().getFullYear() + " Baystar Global Tours. All Rights Reserved";


const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

function stdDateFormat(value) {
  var timestamp = Date.parse(value);
  if (isNaN(timestamp) == false) {
    var d = new Date(timestamp);
    return moment(d).format("MMMM D, YYYY");
  }
}

function timeSince(date) {
  if (typeof date !== "object") {
    date = new Date(date);
  }
  const seconds = Math.floor((Date.now() - date) / 1000);
  const interval = intervals.find((i) => i.seconds < seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
}

function getInitials(str) {
  return str
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
}

function getGreetings() {
  var ndate = new Date();
  var hours = ndate.getHours();
  var message =
    hours < 12
      ? "Good Morning"
      : hours < 18
        ? "Good Afternoon"
        : "Good Evening";
  return message;
}
setInterval(getGreetings, 1000);

function capitalizeFirstLetter(string, find = ' ', replace = ' ') {
  let str = string.toLowerCase();
  if (str != undefined) {
    return str
      .split(find) // Split the string by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(replace); // Join the words with spaces
  } else {
    return str;
  }
}

export default {
  API_ENDPOINT,
  BASE_URL,
  APP_URL,
  version,
  copyright,
  stdDateFormat,
  timeSince,
  getInitials,
  getGreetings,
  capitalizeFirstLetter
};
